<template>
  <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">
                        
                        <label class="mt-1 col-xl-1 mb-2"> Staff: </label>
                        <select v-model="staff_id" class="form-control col-sm-3 mb-2">
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>

                        <span class="col-1">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                        
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="getData()" > Search <i class="fa fa-search"></i> </button>
                        
                    </div>
                    <hr class="no-print">
                    <table class="table table-bordered text-center text-dark mt-4" style="page-break-inside: always; font-size: 12pt !important; color: #262626 !important;">
                        <thead>
                            <th style="font-size: 11pt !important; width: 6.5cm;"> Employee </th>
                            <th style="font-size: 11pt !important;"> Note </th>
                        </thead> 

                        <tbody> 
                            <template v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs && exist_staff_ids.includes(obj.st_id))">
                                <tr :key="'staff' + staff.st_id">
                                    <td colspan="2" style="background-color: #EBEBF0 !important;">
                                        {{ staff.staff_name }}
                                    </td>
                                </tr>
                                <tr v-for="(employee, index) in rows.filter(obj => obj.st_id == staff.st_id)" :key="'staff' + staff.st_id + 'emp' + employee.emp_id + 'inx' + index">
                                    <td>
                                        {{ employee.full_name }}
                                    </td>
                                    <td>
                                        {{ employee.note }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import auth from '../auth';
export default {
    data() {
        return {
            user: {
                username: '',
                role: '',
                en_id: null
            },
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            staff_id: null,
            staffs: [],
            rows: [],
            exist_staff_ids: [],
            showSpecialStaffs: "false"
        }
    },
    beforeCreate(){
        auth("zeroList");
    },
    created() {
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.role = data.type;
            this.user.en_id = data.en_id || null;

            if(this.user.en_id != null){
                this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => {
                    this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                });
            } else {
                this.$http.post("/staff/getData").then(({data}) => {
                    this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                });
            }
        });
    },
    methods: {
        getData() {
            this.rows = []
            this.$http.post("/employee/getZerosByMonthAndYear", {
                month: this.month,
                year: this.year,
                staff_id: this.staff_id
            }).then(({data}) => {
                this.exist_staff_ids = data.map(obj => obj.st_id);
                this.rows = data;

            });
        }
    },
    mounted() {
        this.getData();
    },
    watch: {
        month: function() {
            this.getData();
        },
        year: function() {
            this.getData();
        },
        staff_id: function() {
            this.getData();
        }
    }
}
</script>

<style scoped>
    @media print {
        .no-print {
            display: none;
        }
        .no-print, #nav {
            display: none;
        }
        .container-fluid {
            background: white !important;
        }
        @page {
            size: portrait;
        }
    }
</style>